/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useState } from 'react';
import {
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
  DefaultLeaf,
} from 'slate-react';
import { BaseRange, createEditor, NodeEntry, Range, Transforms } from 'slate';
import { AnnotationLeaf, ParagraphElement } from './editor';
import Labels, { LabelData } from './labels/labels.component';
import { Button, Card, Stack } from '@mui/material';
import { RestartAlt, SaveOutlined } from '@mui/icons-material';
import { DocumentAnnotation } from '../../models/annotator/annotation.model';
import { SequenceAnnotatorLabel } from '../../models/annotator/annotator-project-label';

interface ResumeAnnotationProps {
  annotations: DocumentAnnotation[];
  text: string;
  labels: SequenceAnnotatorLabel[];
  onSave?: () => void;
  onReset?: () => void;
  onUpdate?: (annotations: DocumentAnnotation[]) => void;
}

const ResumeAnnotator = (props: ResumeAnnotationProps) => {
  const [editor] = useState(() => withReact(createEditor()));
  const text = [
    {
      type: 'paragraph',
      children: [{ text: props.text }],
    } as ParagraphElement,
  ];

  const decorate = useCallback(
    (_entry: NodeEntry) => {
      return props.annotations.map((annotation) => ({
        anchor: { path: [0, 0], offset: annotation.range.start },
        focus: { path: [0, 0], offset: annotation.range.end },
        color: props.labels.find((label) => label.id === annotation.label)
          ?.color,
        label: props.labels.find((label) => label.id === annotation.label),
        annotation,
      }));
    },
    [props.annotations, props.labels]
  );

  const renderLeaf = (renderLeafProps: RenderLeafProps) => {
    if (renderLeafProps.leaf.annotation && renderLeafProps.leaf.color) {
      const annotation = renderLeafProps.leaf.annotation;
      const range: BaseRange = {
        anchor: { path: [0, 0], offset: annotation.range.start },
        focus: { path: [0, 0], offset: annotation.range.end },
      };

      const isSelected =
        editor.selection !== null &&
        Range.intersection(editor.selection, range) !== null;

      return (
        <AnnotationLeaf
          renderLeafProps={renderLeafProps}
          selected={isSelected}
          onRemove={() =>
            props.onUpdate?.(
              props.annotations.filter((ann) => ann !== annotation)
            )
          }
          onSelect={() => {
            if (!isSelected) Transforms.select(editor, range);
            else Transforms.deselect(editor);
            props.onUpdate?.([...props.annotations]);
          }}
        />
      );
    }
    return <DefaultLeaf {...renderLeafProps} />;
  };

  const onLabelClick = (label: LabelData) => {
    const range = editor.selection;
    if (!range || range.anchor.offset === range.focus.offset) return;

    const [start, end] = Range.edges(range);
    const nonOverlapping = props.annotations.filter(
      (ann) =>
        Range.intersection(range, {
          anchor: { path: [0, 0], offset: ann.range.start },
          focus: { path: [0, 0], offset: ann.range.end },
        }) == null
    );

    props.onUpdate?.([
      ...nonOverlapping,
      { range: { start: start.offset, end: end.offset }, label: label.id },
    ]);
  };

  const labelsData: LabelData[] = [...props.labels]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((label) => ({
      value: label.name,
      color: label.color,
      id: label.id,
    }));

  const onReset = () => props.onReset?.();

  return (
    <Stack spacing={1} sx={{ height: '100%' }}>
      <Stack spacing={1} direction="row" alignSelf="flex-start">
        <Button
          variant="outlined"
          startIcon={<SaveOutlined />}
          onClick={() => props.onSave?.()}
        >
          Save
        </Button>
        <Button variant="outlined" startIcon={<RestartAlt />} onClick={onReset}>
          Reset
        </Button>
      </Stack>
      <Stack spacing={1} direction="row" sx={{ overflow: 'auto' }} flexGrow={1}>
        <Card
          elevation={4}
          sx={{
            height: '100%',
            display: 'flex',
            flex: '1',
          }}
        >
          <Slate editor={editor} value={text} key={props.text}>
            <Editable
              readOnly
              decorate={decorate}
              renderLeaf={renderLeaf}
              style={{
                padding: '1em',
                overflowY: 'auto',
                flex: '1',
              }}
            />
          </Slate>
        </Card>
        <Card>
          <Labels labels={labelsData} onClick={onLabelClick} />
        </Card>
      </Stack>
    </Stack>
  );
};

export default ResumeAnnotator;
