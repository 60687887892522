import { useCallback } from 'react';
import { useUserProjectData } from '../../context/user-project-data.context';
import { useProtectedUserContext } from '../../context/user.context';
import { ProjectRole } from '../../models/annotator/user-project-data.model';
import { Role } from '../../models/auth/user.model';

export enum AnnotatorProjectPermission {
  RemoveProject = 'RemoveProject',
  RemoveUser = 'RemoveUser',
  AddUser = 'AddUser',
  RemoveAnnotation = 'RemoveAnnotation',
  AddData = 'AddData',
  RemoveData = 'RemoveData',
  AddLabel = 'AddLabel',
  RemoveLabel = 'RemoveLabel',
  EditLabel = 'EditLabel',
  EditUserRole = 'EditUserRole',
}

type PermissionRequirements = {
  permission: AnnotatorProjectPermission;
  projectRoles?: ProjectRole[];
  userRolesOverride?: Role[];
};

const requirements: PermissionRequirements[] = [
  {
    permission: AnnotatorProjectPermission.AddUser,
    projectRoles: [ProjectRole.Owner],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.RemoveUser,
    projectRoles: [ProjectRole.Owner],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.RemoveProject,
    projectRoles: [ProjectRole.Owner],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.AddData,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.RemoveData,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.AddLabel,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.RemoveLabel,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.EditLabel,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.RemoveAnnotation,
    projectRoles: [
      ProjectRole.Owner,
      ProjectRole.Maintainer,
      ProjectRole.Annotator,
    ],
    userRolesOverride: [Role.Admin],
  },
  {
    permission: AnnotatorProjectPermission.EditUserRole,
    projectRoles: [ProjectRole.Owner, ProjectRole.Maintainer],
    userRolesOverride: [Role.Admin],
  },
];

const usePermissions = () => {
  const { user } = useProtectedUserContext();
  const { userProjectData } = useUserProjectData();

  const hasPermission = useCallback(
    (permission: AnnotatorProjectPermission) => {
      const matches = requirements.filter(
        (requirement) => requirement.permission === permission
      );

      return matches.every((match) => {
        return (
          match.projectRoles?.includes(userProjectData.role) ||
          match.userRolesOverride?.includes(user.role)
        );
      });
    },
    [user, userProjectData]
  );

  return { hasPermission };
};

export default usePermissions;
