import { Delete, Edit } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import {
  useProjectLabels,
  useRemoveLabel,
} from '../../../api/annotator/labels.api';
import CreateLabelComponent from '../../../components/annotator/labels/create-label.component';
import DefaultGrid, {
  DefaultHeaderActionProps,
  DefaultItemActionProps,
} from '../../../components/grid/default-grid.component';
import { useUserProjectData } from '../../../context/user-project-data.context';
import { SequenceAnnotatorLabel } from '../../../models/annotator/annotator-project-label';
import { getContrastColor } from '../../../utils';
import usePermissions, {
  AnnotatorProjectPermission,
} from '../../../hooks/annotator/usePermissions';
import EditLabelComponent from '../../../components/annotator/labels/edit-label.component';

const AnnotatorLabelsPage = () => {
  const { userProjectData } = useUserProjectData();
  const { labels, isLoading } = useProjectLabels(userProjectData.project.id);

  const removeLabelMutation = useRemoveLabel();
  const [isCreateLabelOpen, setIsCreateLabelOpen] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<
    SequenceAnnotatorLabel | undefined
  >(undefined);

  const { hasPermission } = usePermissions();

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'key',
      headerName: 'Key',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'color',
      headerName: 'Color',
      hide: false,
      editable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (
        params: GridRenderCellParams<string, SequenceAnnotatorLabel>
      ) => (
        <Box
          sx={{
            backgroundColor: params.value,
            color: getContrastColor(params.value ?? '#000'),
            p: 1,
            borderRadius: 2,
          }}
        >
          {params.value}
        </Box>
      ),
    },
  ];

  const defaultHeaderActions: DefaultHeaderActionProps[] = [
    {
      text: 'Create',
      onClick: () => setIsCreateLabelOpen((old) => !old),
      hidden: !hasPermission(AnnotatorProjectPermission.AddLabel),
    },
    {
      text: 'Remove',
      disableOnNoSelection: true,
      color: 'error',
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveLabel),
      onClick: (items: SequenceAnnotatorLabel[]) =>
        items.forEach((item) =>
          removeLabelMutation.mutate({
            project: userProjectData.project.id,
            key: item.key,
          })
        ),
      confirmationMessage:
        'Are you sure you want to remove the selected labels?',
    },
  ];

  const defaultItemActions: DefaultItemActionProps[] | undefined =
    hasPermission(AnnotatorProjectPermission.EditLabel) ||
    hasPermission(AnnotatorProjectPermission.RemoveLabel)
      ? [
          {
            icon: <Edit />,
            hidden: !hasPermission(AnnotatorProjectPermission.EditLabel),
            onClick: (item: SequenceAnnotatorLabel) => setSelectedLabel(item),
          },
          {
            icon: <Delete />,
            onClick: (item: SequenceAnnotatorLabel) =>
              userProjectData?.project &&
              removeLabelMutation.mutate({
                project: userProjectData?.project?.id,
                key: item.key,
              }),
            confirmationMessage: 'Are you sure you want to remove this label?',
            hidden: !hasPermission(AnnotatorProjectPermission.RemoveLabel),
          },
        ]
      : undefined;

  return (
    <Stack spacing={1} direction="column">
      <DefaultGrid
        loading={isLoading}
        rows={labels}
        columns={columnsDef}
        getRowId={(row: SequenceAnnotatorLabel) => row.key}
        defaultHeaderActions={defaultHeaderActions}
        defaultItemActions={defaultItemActions}
      />
      {isCreateLabelOpen && (
        <CreateLabelComponent
          project={userProjectData.project}
          open={isCreateLabelOpen}
          onClose={() => setIsCreateLabelOpen(false)}
        />
      )}
      {selectedLabel && (
        <EditLabelComponent
          label={selectedLabel}
          project={userProjectData.project}
          open={!!selectedLabel}
          onClose={() => setSelectedLabel(undefined)}
        />
      )}
    </Stack>
  );
};

export default AnnotatorLabelsPage;
