import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { useUserProjectData } from '../../../context/user-project-data.context';
import { useAnnotatorProjectData } from '../../../api/annotator/data.api';
import { useProjectLabels } from '../../../api/annotator/labels.api';
import { useProjectUsers } from '../../../api/annotator/users.api';
import { useAnnotations } from '../../../api/annotator/annotations.api';

interface AnnotatorStep {
  label: string;
  description: React.ReactNode;
  hint?: string;
}

interface AnnotatorStepsProps {
  steps: AnnotatorStep[];
  stepIndex: number;
}

const annotatorSteps: AnnotatorStep[] = [
  {
    label: 'Import data',
    description: (
      <Typography>
        Import the data that you would like to annotate by navigating to the{' '}
        <Link to="dataset">Data</Link> tab. You can import data from various
        formats, such as: PDF, DOCX and TXT.
      </Typography>
    ),
  },
  {
    label: 'Create labels',
    description: (
      <Typography>
        Create the labels that can be used to annotate the data by navigating to{' '}
        the <Link to="labels">Labels</Link> tab.
      </Typography>
    ),
  },
  {
    label: 'Add collaborators',
    description: (
      <Typography>
        You can add project collaborators by navigating to the{' '}
        <Link to="users">Users</Link> tab.
      </Typography>
    ),
    hint: 'Optional',
  },
  {
    label: 'Annotate',
    description: (
      <Typography>
        You can begin annotating the data by navigating to the{' '}
        <Link to="dataset">Data</Link> tab and clicking on 'Annotate' for the
        data that you would like to annotate.
      </Typography>
    ),
    hint: 'Last step',
  },
];

const AnnotatorSteps = ({ steps, stepIndex }: AnnotatorStepsProps) => {
  const [activeStep, setActiveStep] = React.useState(stepIndex);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 'min(600px, 100%)' }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={<Typography variant="caption">{step.hint}</Typography>}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            You have completed all the steps - Great work!
          </Typography>
          <Button
            variant="outlined"
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
          >
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};

const AnnotatorHomePage = () => {
  const { userProjectData } = useUserProjectData();
  const { data } = useAnnotatorProjectData(userProjectData.project.id, 0, 1);
  const { labels } = useProjectLabels(userProjectData.project.id);
  const { users } = useProjectUsers(userProjectData.project.id);
  const { data: annotations } = useAnnotations(
    userProjectData.project.id,
    0,
    1
  );

  const computeStepIndex = () => {
    if (data?.total === 0) {
      return 0;
    }
    if (labels.length === 0) {
      return 1;
    }
    if (users.length === 1) {
      return 2;
    }
    if (annotations?.total === 0) {
      return 3;
    }
    return 4;
  };

  const stepIndex = computeStepIndex();

  return (
    <Stack spacing={2}>
      <Typography variant="h4">Welcome to the Ordinal Annotator!</Typography>
      <AnnotatorSteps
        steps={annotatorSteps}
        stepIndex={stepIndex}
        key={stepIndex}
      />
    </Stack>
  );
};

export default AnnotatorHomePage;
