import { Button, Stack, Typography } from '@mui/material';
import { ModalElement } from '../modal/modal.element.component';

export interface ActionConfirmationModalProps {
  message: string;
  title?: string;
  open: boolean;
  onClose: () => void;
  actionConfirm?: string;
  actionCancel?: string;
  onConfirm?: () => void;
}

const ActionConfirmationModal = ({
  message,
  open,
  onClose,
  title = 'Action Confirmation',
  actionConfirm = 'Yes',
  actionCancel = 'No',
  onConfirm,
}: ActionConfirmationModalProps) => {
  const content = (
    <Stack spacing={2}>
      <Typography>{message}</Typography>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button variant="contained" onClick={onConfirm}>
          {actionConfirm}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          {actionCancel}
        </Button>
      </Stack>
    </Stack>
  );
  return (
    <ModalElement title={title} open={open} onClose={onClose}>
      {content}
    </ModalElement>
  );
};

export default ActionConfirmationModal;
