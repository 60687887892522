import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { AccountCircle, Logout } from '@mui/icons-material';
import { useState } from 'react';
import { User } from '../../models/auth/user.model';

export interface HeaderProps {
  theme: 'dark' | 'light';
  onChangeTheme: () => void;
  onLogout: () => void;
  user: User;
}

const Header = (props: HeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Stack
              flexGrow={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row">
                <Typography
                  fontFamily="sans-serif"
                  variant="h6"
                  noWrap
                  sx={{ mr: 2 }}
                >
                  Ordinal
                </Typography>
                <Button component={Link} to="/" sx={{ color: 'white' }}>
                  Parser
                </Button>
                <Button
                  component={Link}
                  to="/annotator"
                  sx={{ color: 'white' }}
                >
                  Annotator
                </Button>
                <Button
                  component={Link}
                  to="/changelog"
                  sx={{ color: 'white' }}
                >
                  Changelog
                </Button>
                <Button
                  href="https://api.rootstackapi.com/"
                  sx={{ color: 'white' }}
                >
                  API Docs
                </Button>
              </Stack>
              <Stack direction="row">
                <IconButton
                  color="inherit"
                  onClick={() => props.onChangeTheme()}
                >
                  {props.theme === 'dark' ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness4Icon />
                  )}
                </IconButton>
                <IconButton onClick={handleMenu} color="inherit">
                  {<AccountCircle />}
                </IconButton>
              </Stack>
            </Stack>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Stack sx={{ pl: 2, pr: 2, pb: 1 }} direction="row" spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {<AccountCircle />}
                  <Typography>{props.user.email}</Typography>
                </Stack>
              </Stack>
              <MenuItem onClick={props.onLogout}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Logout />
                  <Typography>Log out</Typography>
                </Stack>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </div>
  );
};
export default Header;
