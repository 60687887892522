import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { useUserProject } from '../../../api/annotator/projects.api';
import Loader from '../../../components/loader/loader.component';
import Sidebar from '../../../components/sidebar/sidebar.component';
import AppRoutes from '../../../navigation/app-routes';

interface PageParams {
  id: string;
}

interface AnnotatorProjectPageLayoutProps {
  children?: React.ReactNode;
}

const AnnotatorProjectPageLayout = ({
  children,
}: AnnotatorProjectPageLayoutProps) => {
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Sidebar baseRoute={AppRoutes.AnnotatorProject} />
      <Box
        sx={{
          flexGrow: 1,
          p: 1,
          pb: 5,
          height: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const AnnotatorProjectPage = () => {
  const { id: projectId } = useParams<keyof PageParams>() as PageParams;
  const { project, isLoading } = useUserProject(projectId);

  return (
    <AnnotatorProjectPageLayout>
      {project && <Outlet context={{ userProjectData: project }} />}
      <Loader loading={isLoading} />
    </AnnotatorProjectPageLayout>
  );
};

export default AnnotatorProjectPage;
