import {
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import { useMemo } from 'react';
import { useDarkMode } from 'usehooks-ts';
import { deepmerge } from '@mui/utils';

const baseThemeOptions: ThemeOptions = {
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 1,
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
};

const lightThemeOptions: ThemeOptions = deepmerge({}, baseThemeOptions);

const darkThemeOptions: ThemeOptions = deepmerge(
  {
    palette: {
      mode: 'dark',
    },
  },
  baseThemeOptions
);

export interface ThemeProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProps) => {
  const { isDarkMode } = useDarkMode();
  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(isDarkMode ? darkThemeOptions : lightThemeOptions)
      ),
    [isDarkMode]
  );

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};
