import { Navigate } from 'react-router-dom';
import { Container, Box } from '@mui/material';

export interface ProtectedRouteProps {
  condition: boolean;
  children: JSX.Element;
  redirect: string;
  container?: boolean;
}

const ProtectedRoute = ({
  condition,
  children,
  redirect,
  container,
}: ProtectedRouteProps): JSX.Element => {
  if (!condition) {
    return <Navigate to={redirect} replace />;
  }

  return container ? (
    <Container sx={{ height: '100%' }} component="main">
      {children}
    </Container>
  ) : (
    <Box sx={{ height: '100%' }} component="main">
      {children}
    </Box>
  );
};

export default ProtectedRoute;
