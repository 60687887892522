import React from 'react';
import { User } from '../models/auth/user.model';

export interface UserContextData {
  user: User | null;
}

export interface ProtectedUserContextData {
  user: User;
}

export const UserContext = React.createContext<UserContextData | undefined>(
  undefined
);

export interface UserProviderProps {
  children: React.ReactNode;
  user: User | null;
}

export const UserProvider = ({ children, user }: UserProviderProps) => {
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) throw new Error('No UserContext Provider found.');
  return context;
};

export const useProtectedUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) throw new Error('No UserContext Provider found.');
  if (!context.user) throw new Error('User is not logged in.');
  return context as ProtectedUserContextData;
};
