import {
  ParsedDocumentResult,
  QueuedDocumentResult,
  ReadDocumentResult,
} from '../../models/parser/parsed.document.result.model';
import http, { Response } from '../common';

export class ReadResultNotReady extends Error {}

class ResumeParserService {
  async parse(file: File): Promise<QueuedDocumentResult> {
    const formData = new FormData();
    formData.append('file', file);

    const result = await http.post<QueuedDocumentResult>(
      '/parser/parse',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return result.data;
  }

  async getResult(id: string): Promise<Response<ParsedDocumentResult>> {
    const result = await http.get<ParsedDocumentResult>(`/parser/result/${id}`);
    return result;
  }

  async getReadResult(id: string): Promise<ReadDocumentResult> {
    const result = await http.get<ReadDocumentResult>(`/parser/result/${id}`);
    const data = result.data;
    return data;
  }

  async read(file: File): Promise<QueuedDocumentResult> {
    const formData = new FormData();
    formData.append('file', file);

    const result = await http.post<QueuedDocumentResult>(
      '/parser/read',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return result.data;
  }
}

export default new ResumeParserService();
