import { SequenceAnnotatorLabel } from './annotator-project-label';

export enum ProjectType {
  Sequence = 'Sequence',
  TextClassification = 'Text Classification',
}

export const ProjectTypes = Object.keys(ProjectType);

export interface AnnotatorProject {
  id: string;
  name: string;
  description: string;
  labels: SequenceAnnotatorLabel[];
  type: ProjectType;
  owners: string[];
}
