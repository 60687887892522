import { useState } from 'react';

const usePagination = (initialPage: number, initialPageSize: number) => {
  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  return { page, pageSize, setPage, setPageSize };
};

export default usePagination;
