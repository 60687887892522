import './App.css';
import Header from './components/header/header.component';
import { Box, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { useDarkMode } from 'usehooks-ts';
import useUserSession from './hooks/auth/useAccessToken';
import RoutesComponent from './navigation/routes.component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authService from './services/auth/auth.service';
import { ReactQueryProvider } from './api/query-client';
import { ThemeProvider } from './components/theme/theme';

const App = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const [session, setSession] = useUserSession();
  const isLoggedIn = !!session;

  const handleLogout = async () => {
    setSession(null);
    await authService.logout();
  };

  return (
    <BrowserRouter>
      <ThemeProvider>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          theme={isDarkMode ? 'dark' : 'light'}
        />
        <ReactQueryProvider>
          <div className="App">
            <Stack spacing={2} sx={{ height: '100%' }}>
              {isLoggedIn && (
                <Header
                  user={session}
                  theme={isDarkMode ? 'dark' : 'light'}
                  onChangeTheme={toggle}
                  onLogout={handleLogout}
                />
              )}
              <Box sx={{ height: 'calc(100% - 64px - 16px)' }}>
                <RoutesComponent
                  user={session}
                  onLogin={setSession}
                  onInvalidSession={() => setSession(null)}
                />
              </Box>
            </Stack>
          </div>
        </ReactQueryProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
