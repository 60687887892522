import { Close } from '@mui/icons-material';
import {
  Fade,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

export interface ModalElementProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
}

const modalStyle = {
  position: 'absolute' as const,
  left: '50%',
  top: '20%',
  transform: 'translate(-50%, -50%)',
  minWidth: 'min(70%, 500px)',
  borderRadius: 1,
  margin: 'auto',
  p: 2,
};

export const ModalElement = (props: ModalElementProps) => {
  const { children, open, onClose, title, ...other } = props;
  return (
    <Modal open={open} onClose={onClose} {...other}>
      <Fade in={open}>
        <Paper elevation={3} sx={modalStyle}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{title}</Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            {children}
          </Stack>
        </Paper>
      </Fade>
    </Modal>
  );
};
