import { Stack } from '@mui/material';
import { DocumentAnnotation } from '../../../models/annotator/annotation.model';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import ResumeAnnotator from '../../../components/annotator/resume-annotator.component';
import { useUserProjectData } from '../../../context/user-project-data.context';
import { useState } from 'react';
import { useDocumentText } from '../../../api/annotator/data.api';
import { useCreateAnnotation } from '../../../api/annotator/annotations.api';
import { useProjectLabels } from '../../../api/annotator/labels.api';
import { toast } from 'react-toastify';

interface PageParams {
  dataId: string;
}

const CreateProjectAnnotationPage = () => {
  const { userProjectData } = useUserProjectData();
  const { labels, isLoading: labelsLoading } = useProjectLabels(
    userProjectData.project.id
  );

  const createAnnotation = useCreateAnnotation();
  const { dataId } = useParams<keyof PageParams>() as PageParams;
  const [annotations, setAnnotations] = useState<DocumentAnnotation[]>([]);

  const { text, isLoading: isTextLoading } = useDocumentText(
    userProjectData.project.id,
    dataId
  );

  const navigate = useNavigate();

  const onSaveAnnotations = () =>
    createAnnotation.mutate(
      {
        annotation: {
          dataId,
          annotations,
          text: text ?? '',
        },
        project: userProjectData.project.id,
      },
      {
        onSuccess: () => {
          toast.success('Annotation saved!');
          navigate(
            `/annotator/project/${userProjectData.project.id}/annotations`
          );
        },
        onError: () => toast.error('Unable to save annotation.'),
      }
    );

  const onUpdateAnnotations = (updatedAnnotations: DocumentAnnotation[]) =>
    setAnnotations(updatedAnnotations);

  const onResetAnnotations = () => setAnnotations([]);

  const isLoading = labelsLoading || isTextLoading;
  return (
    <Stack sx={{ height: '100%' }}>
      <ResumeAnnotator
        onUpdate={onUpdateAnnotations}
        onReset={onResetAnnotations}
        onSave={onSaveAnnotations}
        annotations={annotations}
        text={text ?? ''}
        labels={labels}
      />
      <Loader loading={isLoading} />
    </Stack>
  );
};

export default CreateProjectAnnotationPage;
