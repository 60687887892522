import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  AddSequenceAnnotationDto,
  UpdateSequenceAnnotationDto,
} from '../../dtos/resume.annotation.dto';
import {
  SequenceAnnotationResponse,
  SequenceAnnotation,
} from '../../models/annotator/annotation.model';
import annotatorService from '../../services/annotator/annotator.service';
import { ANNOTATIONS_KEY } from '../common';
import { queryClient } from '../query-client';

export const useAnnotations = (
  projectId: string,
  page: number,
  pageSize: number
) => {
  const { data, isLoading, isError, refetch } =
    useQuery<SequenceAnnotationResponse>(
      [...ANNOTATIONS_KEY, projectId, page, pageSize],
      () => {
        return annotatorService.getAnnotations(projectId, page, pageSize);
      }
    );

  return { data, isLoading, isError, refetch };
};

export interface UseAnnotationProps {
  onSuccess?: (data: SequenceAnnotation | undefined) => void;
  onError?: (error: unknown) => void;
}

export const useAnnotation = (
  projectId: string,
  annotationId: string | undefined,
  options: UseAnnotationProps = {}
) => {
  const {
    data: annotation,
    isInitialLoading: isLoading,
    isError,
    refetch,
  } = useQuery<SequenceAnnotation | undefined>(
    [...ANNOTATIONS_KEY, projectId, annotationId],
    () => {
      if (!annotationId) return undefined;
      return annotatorService.getAnnotation(projectId, annotationId);
    },
    {
      enabled: !!annotationId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      initialData: undefined,
      ...options,
    }
  );

  return { annotation, isLoading, isError, refetch };
};

export interface CreateAnnotationData {
  project: string;
  annotation: AddSequenceAnnotationDto;
}

export const useCreateAnnotation = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotationResponse,
    AxiosError,
    CreateAnnotationData
  >(
    ANNOTATIONS_KEY,
    (data) => annotatorService.saveAnnotation(data.project, data.annotation),
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATIONS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface UpdateAnnotationData {
  project: string;
  id: string;
  annotation: UpdateSequenceAnnotationDto;
}

export const useUpdateAnnotation = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotationResponse,
    AxiosError,
    UpdateAnnotationData
  >(
    ANNOTATIONS_KEY,
    (data) =>
      annotatorService.updateAnnotation(data.project, data.id, data.annotation),
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATIONS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface DeleteAnnotationData {
  project: string;
  id: string;
}

export const useRemoveAnnotation = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotationResponse,
    AxiosError,
    DeleteAnnotationData
  >(
    ANNOTATIONS_KEY,
    (data) => {
      return annotatorService.deleteAnnotation(data.project, data.id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATIONS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};
