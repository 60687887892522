import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  AddAnnotatorProjectLabelDto,
  UpdateAnnotatorProjectLabelDto,
} from '../../dtos/annotator.project.dto';
import { SequenceAnnotatorLabel } from '../../models/annotator/annotator-project-label';
import annotatorService from '../../services/annotator/annotator.service';
import { ANNOTATOR_PROJECT_LABELS_KEY } from '../common';
import { queryClient } from '../query-client';

export interface CreateLabelData {
  project: string;
  data: AddAnnotatorProjectLabelDto;
}

export const useCreateLabel = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotatorLabel,
    AxiosError,
    CreateLabelData
  >(
    ANNOTATOR_PROJECT_LABELS_KEY,
    (data) => {
      return annotatorService.addLabel(data.project, data.data);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_LABELS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface UpdateLabelData {
  project: string;
  key: string;
  data: UpdateAnnotatorProjectLabelDto;
}

export const useUpdateLabel = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotatorLabel,
    AxiosError,
    UpdateLabelData
  >(
    ANNOTATOR_PROJECT_LABELS_KEY,
    (data) => {
      return annotatorService.updateLabel(data.project, data.key, data.data);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_LABELS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface RemoveAnnotatorProjectLabelData {
  project: string;
  key: string;
}

export const useRemoveLabel = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    SequenceAnnotatorLabel,
    AxiosError,
    RemoveAnnotatorProjectLabelData
  >(
    ANNOTATOR_PROJECT_LABELS_KEY,
    (data) => {
      return annotatorService.removeLabel(data.project, data.key);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_LABELS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export const useProjectLabels = (projectId: string) => {
  const {
    data: labels,
    isLoading,
    isError,
    error,
  } = useQuery<SequenceAnnotatorLabel[]>(ANNOTATOR_PROJECT_LABELS_KEY, () =>
    annotatorService.getLabels(projectId)
  );

  return { labels: labels ?? [], isLoading, isError, error };
};
