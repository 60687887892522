import { Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDeleteProject,
  useProjects,
} from '../../../api/annotator/projects.api';
import ResumeAnnotatorPojects from '../../../components/annotator/resume.annotator.projects.component';
import { AnnotatorProject } from '../../../models/annotator/annotator-project.model';
import AppRoutes from '../../../navigation/app-routes';

const AnnotatorPage = () => {
  const { projects, isLoading: fetchingProjects } = useProjects({
    onError: () => toast('Unable to retrieve annotator projects.'),
  });
  const navigate = useNavigate();
  const projectMutation = useDeleteProject();

  const onNewProject = () => navigate(AppRoutes.CreateAnnotatorProject);
  const onDeleteSelected = (selectedProjects: AnnotatorProject[]) =>
    selectedProjects.forEach((project) =>
      projectMutation.mutate(project, {
        onError: () => toast('Unable to remove project.'),
      })
    );

  const isLoading = fetchingProjects || projectMutation.isLoading;

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant="h4">My Projects</Typography>
        <Divider />
      </Stack>
      <ResumeAnnotatorPojects
        onCreateProject={onNewProject}
        onRemoveProjects={onDeleteSelected}
        projects={projects}
        loading={isLoading}
      />
    </Stack>
  );
};

export default AnnotatorPage;
