import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CreateAnnotatorProjectDto } from '../../dtos/annotator.project.dto';
import { AnnotatorProject } from '../../models/annotator/annotator-project.model';
import { UserProjectData } from '../../models/annotator/user-project-data.model';
import annotatorService from '../../services/annotator/annotator.service';
import { ANNOTATOR_KEY } from '../common';
import { queryClient } from '../query-client';

export interface UseProjectsProps {
  onError?: (err: unknown) => void;
}

export const useProjects = ({ onError }: UseProjectsProps = {}) => {
  const {
    data: projects,
    isLoading,
    isError,
    refetch,
  } = useQuery<AnnotatorProject[]>(
    ANNOTATOR_KEY,
    () => {
      return annotatorService.getProjects();
    },
    { onError }
  );

  return { projects: projects ?? [], isLoading, isError, refetch };
};

export const useProject = (projectId: string) => {
  const {
    data: project,
    isLoading,
    isError,
    refetch,
  } = useQuery<AnnotatorProject>([...ANNOTATOR_KEY, projectId], () => {
    return annotatorService.getProject(projectId);
  });

  return { project, isLoading, isError, refetch };
};

export const useUserProject = (projectId: string) => {
  const {
    data: project,
    isLoading,
    isError,
    refetch,
  } = useQuery<UserProjectData>([...ANNOTATOR_KEY, projectId], () => {
    return annotatorService.getUserProject(projectId);
  });

  return { project, isLoading, isError, refetch };
};

export const useCreateProject = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    AnnotatorProject,
    AxiosError,
    CreateAnnotatorProjectDto
  >(
    ANNOTATOR_KEY,
    (data) => {
      return annotatorService.createProject(data);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATOR_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface DeleteAnnotatorProjectData {
  id: string;
}

export const useDeleteProject = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    AnnotatorProject,
    AxiosError,
    DeleteAnnotatorProjectData
  >(
    ANNOTATOR_KEY,
    (data) => {
      return annotatorService.deleteProject(data.id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATOR_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};
