import { Box, Container, Divider, Typography } from '@mui/material';

export interface Change {
  short: string;
  description?: string;
}
interface ChangeLogItemProps {
  date: string;
  features?: Change[];
  fixes?: Change[];
  issues?: Change[];
}

interface ChangesListProps {
  changes: Change[];
  title: React.ReactChild;
}

interface ChangeItemProps {
  short: string;
  description?: string;
}

const ChangeItem = (props: ChangeItemProps) => {
  return (
    <>
      <Typography variant="body2">{props.short}</Typography>
      {props.description && (
        <ul>
          <li>
            <Typography variant="body2">{props.description}</Typography>
          </li>
        </ul>
      )}
    </>
  );
};

const ChangesList = (props: ChangesListProps) => {
  return (
    <>
      <Typography variant="subtitle1">{props.title}</Typography>
      <ul>
        {props.changes.map((change) => (
          <li key={change.short}>
            <ChangeItem
              short={change.short}
              description={change.description}
            ></ChangeItem>
          </li>
        ))}
      </ul>
    </>
  );
};

const ChangeLogItem = (props: ChangeLogItemProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h6">{props.date}</Typography>
    <Divider sx={{ mb: 1 }} />
    {props.features && (
      <ChangesList changes={props.features} title={<>&#128640; Features</>} />
    )}
    {props.fixes && (
      <ChangesList changes={props.fixes} title={<>&#128027; Bug Fixes</>} />
    )}
    {props.issues && (
      <ChangesList changes={props.issues} title={<>&#128030; Known Issues</>} />
    )}
  </Box>
);

export interface ChangeLogItemDef {
  date: string;
  features?: Change[];
  fixes?: Change[];
  issues?: Change[];
}

export interface ChangeLogProps {
  items: ChangeLogItemDef[];
}

const ChangeLogPage = (props: ChangeLogProps) => {
  return (
    <Container
      maxWidth="xl"
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
    >
      {props.items.map((item) => {
        return (
          <ChangeLogItem
            key={item.date}
            date={item.date}
            features={item.features}
            issues={item.issues}
            fixes={item.fixes}
          ></ChangeLogItem>
        );
      })}
    </Container>
  );
};

export default ChangeLogPage;
