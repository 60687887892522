import { Backdrop, CircularProgress } from '@mui/material';

export interface LoaderProps {
  loading: boolean;
}

const Loader = ({ loading }: LoaderProps) => (
  <Backdrop open={loading}>
    <CircularProgress sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }} />
  </Backdrop>
);

export default Loader;
