import { useNavigate } from 'react-router-dom';
import {
  useAnnotations,
  useRemoveAnnotation,
} from '../../../api/annotator/annotations.api';
import ResumeAnnotations from '../../../components/annotator/resume-annotations.component';
import { useUserProjectData } from '../../../context/user-project-data.context';
import usePagination from '../../../hooks/common/usePagination';
import { SequenceAnnotation } from '../../../models/annotator/annotation.model';
import annotatorService from '../../../services/annotator/annotator.service';

const ProjectAnnotationsPage = () => {
  const { userProjectData } = useUserProjectData();
  const { page, pageSize, setPage, setPageSize } = usePagination(0, 10);

  const { data, isLoading } = useAnnotations(
    userProjectData.project.id,
    page,
    pageSize
  );
  const removeAnnotationMutation = useRemoveAnnotation();
  const navigate = useNavigate();

  const onDeleteAnnotation = (annotation: SequenceAnnotation) =>
    removeAnnotationMutation.mutate({
      id: annotation.id,
      project: userProjectData.project.id,
    });

  const onEditAnnotation = (annotation: SequenceAnnotation) =>
    navigate(
      `/annotator/project/${userProjectData.project.id}/annotations/${annotation.id}`
    );

  return (
    <ResumeAnnotations
      annotations={data?.annotations ?? []}
      onDelete={onDeleteAnnotation}
      onEdit={onEditAnnotation}
      exportUrl={annotatorService.getExportUrl(userProjectData.project.id)}
      onPageChange={setPage}
      onPageSizeSchange={setPageSize}
      page={page}
      pageSize={pageSize}
      rowCount={data?.total}
      loading={isLoading}
    />
  );
};

export default ProjectAnnotationsPage;
