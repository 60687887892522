import {
  Dataset,
  Home,
  Label,
  People,
  StickyNote2Rounded,
} from '@mui/icons-material';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
} from '@mui/material';
import { NavLink, useMatch } from 'react-router-dom';

type MenuOption = {
  target: string;
  title: string;
  allowSubMatch?: boolean;
  icon: React.ReactNode;
};

const options: MenuOption[] = [
  { title: 'Home', target: '', icon: <Home /> },
  {
    title: 'Annotations',
    target: 'annotations',
    allowSubMatch: true,
    icon: <StickyNote2Rounded />,
  },
  { title: 'Data', target: 'dataset', icon: <Dataset /> },
  { title: 'Labels', target: 'labels', icon: <Label /> },
  { title: 'Users', target: 'users', icon: <People /> },
];

type SidebasrLinkProps = MenuOption & {
  baseRoute: string;
};

const SidebarLink = ({
  title,
  target,
  icon,
  baseRoute,
  allowSubMatch,
}: SidebasrLinkProps) => {
  const isMatch = useMatch(
    `${baseRoute}/${target + (allowSubMatch ? '/*' : '')}`
  );
  return (
    <ListItemButton
      selected={!!isMatch}
      key={title}
      component={NavLink}
      to={target}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>
        {title}
      </Typography>
    </ListItemButton>
  );
};

export interface SidebarProps {
  baseRoute: string;
}

const Sidebar = ({ baseRoute }: SidebarProps) => {
  const drawerWidth = { xs: 60, sm: 240 };

  return (
    <Drawer
      PaperProps={{
        elevation: 1,
      }}
      sx={{
        flexShrink: 0,
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          overflow: 'hidden',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <List>
        {options.map((item) => (
          <SidebarLink baseRoute={baseRoute} key={item.title} {...item} />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
