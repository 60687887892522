import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { UploadAnnotatorDataDto } from '../../dtos/annotator.project.dto';
import {
  AnnotatorData,
  AnnotatorDataResponse,
} from '../../models/annotator/annotator-data.model';
import {
  QueuedDocumentResult,
  ReadDocumentResult,
} from '../../models/parser/parsed.document.result.model';
import annotatorService from '../../services/annotator/annotator.service';
import { ANNOTATOR_DATA_KEY, ANNOTATOR_PARSER_KEY } from '../common';
import { queryClient } from '../query-client';

export interface UploadDataData {
  project: string;
  data: UploadAnnotatorDataDto;
}

export const useUploadData = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    AnnotatorData[],
    AxiosError,
    UploadDataData[]
  >(
    ANNOTATOR_DATA_KEY,
    (data) => {
      return Promise.all(
        data.map((item) => annotatorService.uploadData(item.project, item.data))
      );
    },
    { onSuccess: () => queryClient.invalidateQueries(ANNOTATOR_DATA_KEY) }
  );

  return { mutate, isLoading, isError, error };
};

export interface RemoveAnnotatorDataData {
  project: string;
  id: string;
}

export const useRemoveData = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    AnnotatorData,
    AxiosError,
    RemoveAnnotatorDataData
  >(
    ANNOTATOR_DATA_KEY,
    (data) => annotatorService.removeData(data.project, data.id),
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATOR_DATA_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export const useAnnotatorProjectData = (
  projectId: string,
  page: number,
  pageSize: number
) => {
  const { data, isLoading, isError, refetch } = useQuery<AnnotatorDataResponse>(
    [...ANNOTATOR_DATA_KEY, projectId, page, pageSize],
    () => {
      return annotatorService.getProjectData(projectId, page, pageSize);
    }
  );

  return { data, isLoading, isError, refetch };
};

export const useAnnotatorData = (project: string, dataId: string) => {
  const { data, isLoading, isError, refetch } = useQuery<AnnotatorData>(
    [...ANNOTATOR_DATA_KEY, project, dataId],
    () => {
      return annotatorService.getDataById(project, dataId);
    }
  );

  return { data, isLoading, isError, refetch };
};

export interface ParserData {
  project: string;
  id: string;
}

export const useParseData = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    QueuedDocumentResult,
    AxiosError,
    ParserData
  >(
    ANNOTATOR_PARSER_KEY,
    (data) => annotatorService.parseData(data.project, data.id),
    {
      onSuccess: () => queryClient.invalidateQueries(ANNOTATOR_DATA_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export const useGetDataParseResult = (id: string | undefined) => {
  const {
    data,
    isLoading: isDataLoading,
    isError: isDataError,
    refetch,
  } = useQuery<ReadDocumentResult | undefined>(
    [...ANNOTATOR_PARSER_KEY, id],
    () => {
      if (!id) return undefined;
      return annotatorService.getParseResult(id);
    },
    {
      refetchInterval: (res: ReadDocumentResult | undefined) => {
        return res?.status === 'QUEUED' ? 200 : 0;
      },
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
  const isLoading = isDataLoading || data?.status === 'QUEUED';
  const isError = isDataError || data?.status === 'ERROR';
  return { data, isLoading, isError, refetch };
};

export const useDocumentText = (project: string, documentId: string) => {
  const mutation = useParseData();

  const [queryResult, setQueryResult] = useState<
    QueuedDocumentResult | undefined
  >(undefined);

  const {
    data,
    isLoading: parserDataLoading,
    isError: parserDataError,
  } = useGetDataParseResult(queryResult?.id);

  useEffect(() => {
    mutation.mutate(
      { project, id: documentId },
      {
        onSuccess: (result) => setQueryResult(result),
        onError: () => setQueryResult(undefined),
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, project]);

  const isLoading = mutation.isLoading || parserDataLoading;
  const isError =
    mutation.isError || parserDataError || queryResult?.status === 'ERROR';
  return { text: data?.data?.text, isLoading, isError };
};
