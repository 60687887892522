import {
  AddAnnotatorProjectLabelDto,
  AddUserToProjectDto,
  CreateAnnotatorProjectDto,
  UpdateAnnotatorProjectLabelDto,
  UpdateProjectUserDto,
  UploadAnnotatorDataDto,
} from '../../dtos/annotator.project.dto';
import {
  AddSequenceAnnotationDto,
  UpdateSequenceAnnotationDto,
} from '../../dtos/resume.annotation.dto';
import {
  AnnotatorData,
  AnnotatorDataResponse,
} from '../../models/annotator/annotator-data.model';
import { SequenceAnnotatorLabel } from '../../models/annotator/annotator-project-label';
import { AnnotatorProject } from '../../models/annotator/annotator-project.model';
import { UserProjectData } from '../../models/annotator/user-project-data.model';
import {
  SequenceAnnotationResponse,
  SequenceAnnotation,
} from '../../models/annotator/annotation.model';
import http from '../common';
import {
  QueuedDocumentResult,
  ReadDocumentResult,
} from '../../models/parser/parsed.document.result.model';
import { BACKEND_SERVER_URL } from '../../config/config';

class AnnotatorService {
  async getProjects(): Promise<AnnotatorProject[]> {
    const result = await http.get<AnnotatorProject[]>(`annotator/project`);
    return result.data;
  }

  async createProject(
    project: CreateAnnotatorProjectDto
  ): Promise<AnnotatorProject> {
    const result = await http.post<AnnotatorProject>(
      `annotator/project`,
      project
    );
    return result.data;
  }

  async deleteProject(project: string): Promise<AnnotatorProject> {
    const result = await http.delete<AnnotatorProject>(
      `annotator/project/${project}`
    );
    return result.data;
  }

  async getProject(projectId: string): Promise<AnnotatorProject> {
    const result = await http.get<AnnotatorProject>(
      `annotator/project/${projectId}`
    );
    return result.data;
  }

  async getUserProject(projectId: string): Promise<UserProjectData> {
    const result = await http.get<UserProjectData>(
      `annotator/project/${projectId}/user`
    );
    return result.data;
  }

  async saveAnnotation(
    project: string,
    annotation: AddSequenceAnnotationDto
  ): Promise<SequenceAnnotationResponse> {
    const result = await http.post<SequenceAnnotationResponse>(
      `annotator/sequence/project/${project}/annotations`,
      annotation
    );
    return result.data;
  }

  async getAnnotations(
    project: string,
    page: number,
    pageSize: number
  ): Promise<SequenceAnnotationResponse> {
    const result = await http.get<SequenceAnnotationResponse>(
      `annotator/project/${project}/annotations?page=${page}&pageSize=${pageSize}`
    );
    return result.data;
  }

  async getAnnotation(
    project: string,
    annotationId: string
  ): Promise<SequenceAnnotation> {
    const result = await http.get<SequenceAnnotation>(
      `annotator/project/${project}/annotations/${annotationId}`
    );
    return result.data;
  }

  async updateAnnotation(
    project: string,
    id: string,
    dto: UpdateSequenceAnnotationDto
  ): Promise<SequenceAnnotationResponse> {
    const result = await http.put<SequenceAnnotationResponse>(
      `annotator/sequence/project/${project}/annotations/${id}`,
      dto
    );
    return result.data;
  }

  async deleteAnnotation(
    project: string,
    id: string
  ): Promise<SequenceAnnotationResponse> {
    const result = await http.delete<SequenceAnnotationResponse>(
      `annotator/project/${project}/annotations/${id}`
    );
    return result.data;
  }

  async getUsers(project: string): Promise<UserProjectData[]> {
    const result = await http.get<UserProjectData[]>(
      `annotator/project/${project}/users`
    );
    return result.data;
  }

  async addUser(
    project: string,
    dto: AddUserToProjectDto
  ): Promise<UserProjectData> {
    const result = await http.post<UserProjectData>(
      `annotator/project/${project}/users`,
      dto
    );
    return result.data;
  }

  async removeUser(project: string, user: string): Promise<UserProjectData> {
    const result = await http.delete<UserProjectData>(
      `annotator/project/${project}/users/${user}`
    );
    return result.data;
  }

  async updateUser(
    project: string,
    user: string,
    dto: UpdateProjectUserDto
  ): Promise<UserProjectData> {
    const result = await http.put<UserProjectData>(
      `annotator/project/${project}/users/${user}`,
      dto
    );
    return result.data;
  }

  async uploadData(
    project: string,
    dto: UploadAnnotatorDataDto
  ): Promise<AnnotatorData> {
    const formData = new FormData();
    formData.append('file', dto.file);

    const result = await http.post<AnnotatorData>(
      `annotator/project/${project}/data`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return result.data;
  }

  async getProjectData(
    projectId: string,
    page: number,
    pageSize: number
  ): Promise<AnnotatorDataResponse> {
    const result = await http.get<AnnotatorDataResponse>(
      `annotator/project/${projectId}/data?page=${page}&pageSize=${pageSize}`
    );
    return result.data;
  }

  async getDataById(project: string, dataId: string): Promise<AnnotatorData> {
    const result = await http.get<AnnotatorData>(
      `annotator/project/${project}/data/${dataId}`
    );
    return result.data;
  }

  async removeData(project: string, data: string): Promise<AnnotatorData> {
    const result = await http.delete<AnnotatorData>(
      `annotator/project/${project}/data/${data}`
    );
    return result.data;
  }

  async parseData(
    project: string,
    data: string
  ): Promise<QueuedDocumentResult> {
    const result = await http.post<QueuedDocumentResult>(
      `annotator/sequence/project/${project}/data/${data}/parse`
    );
    return result.data;
  }

  async getParseResult(id: string): Promise<ReadDocumentResult> {
    const result = await http.get<ReadDocumentResult>(
      `annotator/sequence/result/${id}`
    );
    const data = result.data;
    return data;
  }

  async addLabel(
    project: string,
    dto: AddAnnotatorProjectLabelDto
  ): Promise<SequenceAnnotatorLabel> {
    const result = await http.post<SequenceAnnotatorLabel>(
      `annotator/sequence/project/${project}/labels`,
      dto
    );
    return result.data;
  }

  async removeLabel(
    project: string,
    key: string
  ): Promise<SequenceAnnotatorLabel> {
    const result = await http.delete<SequenceAnnotatorLabel>(
      `annotator/sequence/project/${project}/labels/${key}`
    );
    return result.data;
  }

  async updateLabel(
    project: string,
    key: string,
    dto: UpdateAnnotatorProjectLabelDto
  ): Promise<SequenceAnnotatorLabel> {
    const result = await http.put<SequenceAnnotatorLabel>(
      `annotator/sequence/project/${project}/labels/${key}`,
      dto
    );
    return result.data;
  }

  async getLabels(projectId: string): Promise<SequenceAnnotatorLabel[]> {
    const result = await http.get<SequenceAnnotatorLabel[]>(
      `annotator/project/${projectId}/labels`
    );
    return result.data;
  }

  getExportUrl = (project: string) => {
    return `${BACKEND_SERVER_URL}/annotator/sequence/project/${project}/annotations/export`;
  };
}

export default new AnnotatorService();
