import { useEffect } from 'react';
import {
  Routes,
  useNavigate,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { User } from '../models/auth/user.model';
import ChangeLogPage from '../pages/changelog/changelog.page';
import changes from '../pages/changelog/changes';
import HomePage from '../pages/home/home.page';
import LoginPage from '../pages/login/login.page';
import ProtectedRoute from '../components/routes/protected.route';
import AuthService from '../services/auth/auth.service';
import RegisterPage from '../pages/register/register.page';
import AnnotatorPage from '../pages/annotator/projects/annotator-projects.page';
import AppRoutes from './app-routes';
import CreateAnnotatorProjectPage from '../pages/annotator/projects/create-project.page';
import AnnotatorProjectPage from '../pages/annotator/projects/annotator-project-page';
import CreateProjectAnnotationPage from '../pages/annotator/annotations/create-annotation.page';
import ProjectAnnotationsPage from '../pages/annotator/annotations/annotations.page';
import AnnotatorDataPage from '../pages/annotator/data/annotator-data.page';
import AnnotatorLabelsPage from '../pages/annotator/labels/labels.page';
import AnnotatorProjectUsersPage from '../pages/annotator/users/users.page';
import { UserProvider } from '../context/user.context';
import AnnotatorHomePage from '../pages/annotator/home/annotator-home-page';
import EditProjectAnnotationPage from '../pages/annotator/annotations/edit-annotation.page';

export interface RoutesComponentProps {
  user: User | null;
  onLogin: (userSession: User) => void;
  onInvalidSession: () => void;
}

const RoutesComponent = ({
  user,
  onLogin,
  onInvalidSession,
}: RoutesComponentProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    const validateSession = async () => {
      const isValidSession = await AuthService.verify();
      if (!isValidSession) onInvalidSession();
    };

    void validateSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isLoggedIn = !!user;

  return (
    <UserProvider user={user}>
      <Routes>
        <Route
          path={AppRoutes.Home}
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login" container>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.Annotator}
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login" container>
              <AnnotatorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.CreateAnnotatorProject}
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login" container>
              <CreateAnnotatorProjectPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.AnnotatorProject}
          element={
            <ProtectedRoute condition={isLoggedIn} redirect="/login">
              <AnnotatorProjectPage />
            </ProtectedRoute>
          }
        >
          <Route
            path=""
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <AnnotatorHomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="data/:dataId"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <CreateProjectAnnotationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="annotations/:annotationId"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <EditProjectAnnotationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="dataset"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <AnnotatorDataPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <AnnotatorProjectUsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="labels"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <AnnotatorLabelsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="annotations"
            element={
              <ProtectedRoute
                condition={isLoggedIn}
                redirect="/login"
                container
              >
                <ProjectAnnotationsPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={AppRoutes.Login}
          element={
            <ProtectedRoute condition={!isLoggedIn} redirect="/" container>
              <LoginPage
                onLogin={(session) => {
                  onLogin(session);
                  navigate(AppRoutes.Home);
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.Register}
          element={
            <ProtectedRoute condition={!isLoggedIn} redirect="/" container>
              <RegisterPage
                onRegister={() => {
                  navigate(AppRoutes.Login);
                }}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.Changelog}
          element={
            <ProtectedRoute
              condition={isLoggedIn}
              redirect={AppRoutes.Login}
              container
            >
              <ChangeLogPage items={changes} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={AppRoutes.Home} replace />} />
      </Routes>
    </UserProvider>
  );
};

export default RoutesComponent;
