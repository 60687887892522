enum AppRoutes {
  Home = '/',
  Annotator = '/annotator',
  CreateAnnotatorProject = '/annotator/project/new',
  AnnotatorProject = '/annotator/project/:id',
  Login = '/login',
  Register = '/register',
  Changelog = '/changelog',
}

export default AppRoutes;
