import { useState } from 'react';
import ActionConfirmationModal from './action-confirmation-modal.component';

export interface ConfirmationProps {
  message?: string;
  onClick?: () => void;
}

const withConfirmation = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P & ConfirmationProps) => {
    const { message, onClick, ...rest } = props;
    const [isConfirmationOpen, setIsConfirmationOpen] =
      useState<boolean>(false);

    return (
      <>
        <WrappedComponent
          {...(rest as P)}
          onClick={() => setIsConfirmationOpen(true)}
        />
        <ActionConfirmationModal
          open={isConfirmationOpen}
          onConfirm={() => {
            onClick?.();
            setIsConfirmationOpen(false);
          }}
          onClose={() => setIsConfirmationOpen(false)}
          message={message ?? ''}
        />
      </>
    );
  };
};

export default withConfirmation;
