import { RemoveCircle } from '@mui/icons-material';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import {
  GridCellEditCommitParams,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useProjectUsers,
  useUserProjectMutations,
} from '../../../api/annotator/users.api';
import { User } from '../../../models/auth/user.model';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProjectRole,
  ProjectRoles,
  UserProjectData,
} from '../../../models/annotator/user-project-data.model';
import DefaultGrid, {
  DefaultHeaderActionProps,
  DefaultItemActionProps,
} from '../../../components/grid/default-grid.component';
import { ModalElement } from '../../../components/modal/modal.element.component';
import { toast } from 'react-toastify';
import { useUserProjectData } from '../../../context/user-project-data.context';
import usePermissions, {
  AnnotatorProjectPermission,
} from '../../../hooks/annotator/usePermissions';
import { useProtectedUserContext } from '../../../context/user.context';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email provided.')
      .required('The user email is required.'),
    role: yup
      .mixed<ProjectRole>()
      .nullable()
      .required('The user role is required.'),
  })
  .required();

export interface AddUserToProjectData {
  email: string;
  role: ProjectRole;
}

export interface UserControlsProps {
  onAddUser: (user: AddUserToProjectData) => void;
  open: boolean;
  onClose: () => void;
}

const UserControls = ({ onAddUser, open, onClose }: UserControlsProps) => {
  const { control, handleSubmit } = useForm<AddUserToProjectData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      email: '',
      role: ProjectRole.Annotator,
    },
  });

  const onSubmit = handleSubmit((data) => {
    onAddUser(data);
  });

  const content = (
    <Stack component="form" noValidate spacing={2} onSubmit={onSubmit}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              required
              placeholder="Enter the email of the collaborator"
              label="Email"
              autoComplete="email"
              type="email"
              helperText={fieldState.error?.message}
              error={!!fieldState.error}
            />
          );
        }}
      />
      <Controller
        name="role"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Autocomplete
              options={ProjectRoles}
              onChange={(e, v) => field.onChange(v)}
              value={field.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  placeholder="Enter the role of the collaborator"
                  label="Role"
                />
              )}
            />
          );
        }}
      />
      <Stack direction="row">
        <Button type="submit" variant="contained">
          Add
        </Button>
        <Button sx={{ ml: 1 }} onClick={() => onClose?.()} variant="outlined">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <ModalElement onClose={onClose} open={open} title="Add collaborator">
      {content}
    </ModalElement>
  );
};

const AnnotatorProjectUsersPage = () => {
  const { userProjectData } = useUserProjectData();
  const { hasPermission } = usePermissions();
  const { user } = useProtectedUserContext();

  const { users, isLoading } = useProjectUsers(userProjectData.project.id);
  const { addUserToProject, removeUserFromProject, updateProjectUser } =
    useUserProjectMutations();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);

  const removeProjectUser = (item: UserProjectData) =>
    removeUserFromProject.mutate({
      project: item.project.id,
      id: item.user.id,
    });

  const onConfirmRemoveUsers = (selectedUsers: UserProjectData[]) =>
    selectedUsers.forEach((u) => removeProjectUser(u));

  const onAddUser = (data: AddUserToProjectData) => {
    addUserToProject.mutate(
      { data, project: userProjectData.project.id },
      {
        onSuccess: () => setIsAddUserModalOpen(false),
        onError: () => toast('Unable to add user to project.'),
      }
    );
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'user',
      headerName: 'Email',
      hide: false,
      editable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<User>) => params.value?.email,
    },
    {
      field: 'role',
      headerName: 'Role',
      hide: false,
      editable: hasPermission(AnnotatorProjectPermission.EditUserRole),
      flex: 1,
      type: 'singleSelect',
      valueOptions: ProjectRoles,
    },
  ];

  const defaultHeaderActions: DefaultHeaderActionProps[] = [
    {
      text: 'Add User',
      hidden: !hasPermission(AnnotatorProjectPermission.AddUser),
      onClick: () => setIsAddUserModalOpen((old) => !old),
    },
    {
      text: 'Remove',
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveUser),
      disableOnNoSelection: true,
      color: 'error',
      onClick: (data: UserProjectData[]) => onConfirmRemoveUsers(data),
      confirmationMessage:
        'Are you sure you want to remove the selected users?',
    },
  ];

  const defaultItemActions: DefaultItemActionProps[] | undefined =
    hasPermission(AnnotatorProjectPermission.RemoveUser)
      ? [
          {
            icon: <RemoveCircle />,
            onClick: (item: UserProjectData) => removeProjectUser(item),
            confirmationMessage: 'Are you sure you want to remove this user?',
            disabled: (item: UserProjectData) => item.user.id === user.id,
          },
        ]
      : undefined;

  return (
    <>
      <DefaultGrid
        loading={isLoading}
        columns={columnsDef}
        rows={users}
        defaultHeaderActions={defaultHeaderActions}
        defaultItemActions={defaultItemActions}
        isRowSelectable={(params: GridRowParams<UserProjectData>) =>
          params.row.user.id !== user.id
        }
        onCellEditCommit={(params: GridCellEditCommitParams) => {
          if (params.field === 'role') {
            const project = users.filter((u) => u.id === params.id)[0];
            updateProjectUser.mutate({
              project: userProjectData.project.id,
              id: project.user.id,
              data: {
                role: params.value as ProjectRole,
              },
            });
          }
        }}
      />
      {isAddUserModalOpen && (
        <UserControls
          open={isAddUserModalOpen}
          onClose={() => setIsAddUserModalOpen(false)}
          onAddUser={onAddUser}
        />
      )}
    </>
  );
};

export default AnnotatorProjectUsersPage;
