/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  ButtonPropsColorOverrides,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowsProp,
  GridSelectionModel,
  GridValidRowModel,
  GridRowParams,
  GridCellEditCommitParams,
} from '@mui/x-data-grid';
import { getRowIdFromRowModel } from '@mui/x-data-grid/hooks/features/rows/gridRowsUtils';
import { useState, useMemo } from 'react';
import { OverridableStringUnion } from '@mui/types';
import withConfirmation from '../action-confirmation/with-confirmation.hoc';

export interface ActionProps {
  text?: string;
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
  label?: string;
  confirmationMessage?: string;
  href?: string;
}

export interface DefaultHeaderActionProps<R extends GridValidRowModel = any>
  extends ActionProps {
  onClick?: (selectedItems: R[]) => void;
  disableOnNoSelection?: boolean;
  disabled?: boolean | ((selectedItems: R[]) => boolean);
  hidden?: boolean | ((selectedItems: R[]) => boolean);
}

export interface ActionsGridProps<R extends GridValidRowModel> {
  columns: GridColumns<R>;
  rows?: GridRowsProp<R>;
  loading?: boolean;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  getRowId?: GridRowIdGetter<R>;
  sx?: SxProps<Theme>;
  header?: React.ReactNode;
  onSelectionChanged?: (rows: R[]) => void;
  defaultHeaderActions?: DefaultHeaderActionProps<R>[];
  defaultItemActions?: DefaultHeaderActionProps<R>[];
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  paginationMode?: 'client' | 'server';
  isRowSelectable?: (params: GridRowParams<R>) => boolean;
  onCellEditCommit?: (params: GridCellEditCommitParams) => void;
}

interface DefaultHeaderActionsProps<R extends GridValidRowModel> {
  actions: DefaultHeaderActionProps<R>[];
  items: R[];
}

interface DefaultItemButtonProps {
  action: DefaultItemActionProps | DefaultHeaderActionProps;
  onClick?: () => void;
  disabled?: boolean;
}

const DefaultItemButton = ({
  action,
  onClick,
  disabled,
}: DefaultItemButtonProps) => {
  const ButtonType = action.text ? Button : IconButton;
  const FinalButtonType = useMemo(
    () =>
      action.confirmationMessage ? withConfirmation(ButtonType) : ButtonType,
    [action.confirmationMessage, ButtonType]
  );

  const extraProps = action.confirmationMessage
    ? {
        message: action.confirmationMessage,
      }
    : {};

  return (
    <FinalButtonType
      color={action.color}
      sx={action.sx}
      disabled={disabled}
      onClick={() => onClick?.()}
      variant="contained"
      href={action.href}
      {...extraProps}
    >
      {action.text || action.icon}
    </FinalButtonType>
  );
};

const DefaultHeaderActions = <R extends GridValidRowModel = any>({
  actions,
  items,
}: DefaultHeaderActionsProps<R>) => {
  const isActionDisabled = (action: DefaultHeaderActionProps<R>) => {
    if (typeof action.disabled === 'boolean') {
      return action.disabled;
    } else {
      return action.disabled?.(items);
    }
  };

  const isActionHidden = (action: DefaultHeaderActionProps<R>) => {
    if (typeof action.hidden === 'boolean') {
      return action.hidden;
    } else {
      return action.hidden?.(items);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      {actions.map(
        (action) =>
          !isActionHidden(action) && (
            <DefaultItemButton
              disabled={
                isActionDisabled(action) ||
                (action.disableOnNoSelection && items.length === 0)
              }
              key={action.text}
              action={action}
              onClick={() => action.onClick?.(items)}
            />
          )
      )}
    </Stack>
  );
};

export interface DefaultItemActionProps<R extends GridValidRowModel = any>
  extends ActionProps {
  label?: string;
  onClick?: (item: R) => void;
  disabled?: boolean | ((selectedItem: R) => boolean);
  hidden?: boolean | ((selectedItem: R) => boolean);
}

interface DefaultItemActionsProps<R extends GridValidRowModel = any> {
  actions: DefaultItemActionProps<R>[];
  item: R;
}

const DefaultItemActions = ({ actions, item }: DefaultItemActionsProps) => {
  const isActionDisabled = (action: DefaultItemActionProps) => {
    if (typeof action.disabled === 'boolean') {
      return action.disabled;
    } else {
      return action.disabled?.(item);
    }
  };

  const isActionHidden = (action: DefaultItemActionProps) => {
    if (typeof action.hidden === 'boolean') {
      return action.hidden;
    } else {
      return action.hidden?.(item);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      {actions.map(
        (action, idx) =>
          !isActionHidden(action) && (
            <DefaultItemButton
              disabled={isActionDisabled(action)}
              key={idx}
              action={action}
              onClick={() => action.onClick?.(item)}
            />
          )
      )}
    </Stack>
  );
};

const DefaultGrid = <R extends GridValidRowModel = any>({
  rows,
  columns,
  loading,
  pageSize = 10,
  page,
  sx,
  header,
  defaultHeaderActions,
  defaultItemActions,
  onSelectionChanged,
  getRowId,
  onPageChange,
  onPageSizeChange,
  rowCount,
  paginationMode = 'client',
  isRowSelectable,
  onCellEditCommit,
}: ActionsGridProps<R>) => {
  const [selectedItems, setSelectedItems] = useState<R[]>([]);

  const columnsDef: GridColDef[] = !defaultItemActions
    ? columns
    : [
        ...columns,
        {
          field: 'DEFAULT_GRID_ACTIONS',
          headerName: 'Actions',
          hide: false,
          editable: false,
          sortable: false,
          filterable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<unknown, R>) => (
            <DefaultItemActions
              actions={defaultItemActions}
              item={params.row}
            />
          ),
        },
      ];
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        {defaultHeaderActions && (
          <DefaultHeaderActions
            actions={defaultHeaderActions}
            items={selectedItems}
          />
        )}
        {header}
      </Stack>
      <Paper>
        <DataGrid
          isRowSelectable={isRowSelectable}
          page={page}
          onPageChange={(p: number) => onPageChange?.(p)}
          onPageSizeChange={(s) => onPageSizeChange?.(s)}
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          rowCount={rowCount}
          pageSize={pageSize}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          loading={loading}
          sx={{ padding: 1, ...sx }}
          rows={rows ?? []}
          columns={columnsDef}
          getRowId={getRowId}
          paginationMode={paginationMode}
          onCellEditCommit={onCellEditCommit}
          onSelectionModelChange={(ids: GridSelectionModel) => {
            const result = rows?.filter((row) =>
              ids.some(
                (id) =>
                  (!!getRowId ? getRowId(row) : getRowIdFromRowModel(row)) ===
                  id
              )
            );
            setSelectedItems(result ?? []);
            onSelectionChanged?.(result ?? []);
          }}
        />
      </Paper>
    </Stack>
  );
};

export default DefaultGrid;
