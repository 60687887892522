import { Delete, Edit } from '@mui/icons-material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import usePermissions, {
  AnnotatorProjectPermission,
} from '../../hooks/annotator/usePermissions';
import { SequenceAnnotation } from '../../models/annotator/annotation.model';
import { User } from '../../models/auth/user.model';
import DefaultGrid, {
  DefaultHeaderActionProps,
  DefaultItemActionProps,
} from '../grid/default-grid.component';

export interface ResumeAnnotationsProps {
  exportUrl?: string;
  annotations: SequenceAnnotation[];
  onDelete?: (annotation: SequenceAnnotation) => void;
  onEdit?: (annotation: SequenceAnnotation) => void;
  onPageChange?: (page: number) => void;
  onPageSizeSchange?: (pageSize: number) => void;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  loading?: boolean;
}

const ResumeAnnotations = ({
  annotations,
  onDelete,
  onEdit,
  exportUrl,
  onPageChange,
  page,
  pageSize,
  onPageSizeSchange,
  rowCount,
  loading,
}: ResumeAnnotationsProps) => {
  const { hasPermission } = usePermissions();

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      hide: false,
      editable: false,
      flex: 1,
    },
    {
      field: 'user',
      headerName: 'Annotator',
      hide: false,
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<User>) => params.value?.email,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      hide: false,
      editable: false,
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams<string>) =>
        param.value ? new Date(param.value).toLocaleString() : 'N/A',
    },
  ];

  const defaultHeaderActions: DefaultHeaderActionProps[] = [
    {
      text: 'Remove',
      color: 'error',
      onClick: (items: SequenceAnnotation[]) =>
        items.forEach((item) => onDelete?.(item)),
      disableOnNoSelection: true,
      confirmationMessage:
        'Are you sure you want to remove the selected annotation(s)?',
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveAnnotation),
    },
    {
      text: 'Export',
      href: exportUrl,
    },
  ];

  const defaultItemActions: DefaultItemActionProps[] = [
    {
      icon: <Edit />,
      onClick: (item: SequenceAnnotation) => onEdit?.(item),
    },
    {
      icon: <Delete />,
      onClick: (item: SequenceAnnotation) => onDelete?.(item),
      confirmationMessage: 'Are yo sure you want to remove this annotation?',
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveAnnotation),
    },
  ];

  return (
    <DefaultGrid
      rows={[...annotations]}
      columns={columnsDef}
      defaultHeaderActions={defaultHeaderActions}
      defaultItemActions={defaultItemActions}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeSchange}
      page={page}
      pageSize={pageSize}
      rowCount={rowCount ?? 0}
      paginationMode="server"
      loading={loading}
    />
  );
};

export default ResumeAnnotations;
