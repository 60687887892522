import { DeleteOutline, OpenInNew } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useProtectedUserContext } from '../../context/user.context';
import { AnnotatorProject } from '../../models/annotator/annotator-project.model';
import DefaultGrid, {
  DefaultHeaderActionProps,
  DefaultItemActionProps,
} from '../grid/default-grid.component';

export interface ResumeAnnotatorPojectsProps {
  projects: AnnotatorProject[];
  loading?: boolean;
  onCreateProject?: () => void;
  onRemoveProjects?: (projects: AnnotatorProject[]) => void;
}

const ResumeAnnotatorPojects = ({
  projects,
  loading,
  onCreateProject,
  onRemoveProjects,
}: ResumeAnnotatorPojectsProps) => {
  const navigate = useNavigate();
  const { user } = useProtectedUserContext();

  const openProject = (id: string) => {
    navigate(`/annotator/project/${id}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      maxWidth: 200,
      flex: 1,
    },
  ];

  const defaultItemActions: DefaultItemActionProps[] = [
    {
      icon: <OpenInNew />,
      onClick: (item: AnnotatorProject) => openProject(item.id),
    },
    {
      icon: <DeleteOutline />,
      confirmationMessage: 'Are you sure you want to remove this project?',
      onClick: (item: AnnotatorProject) => onRemoveProjects?.([item]),
      disabled: (item: AnnotatorProject) => !item.owners.includes(user.id),
    },
  ];

  const defaultHeaderActions: DefaultHeaderActionProps[] = [
    {
      text: 'Create Project',
      onClick: () => onCreateProject?.(),
    },
    {
      text: 'Remove',
      disableOnNoSelection: true,
      onClick: (selected: AnnotatorProject[]) => onRemoveProjects?.(selected),
      confirmationMessage:
        'Are you sure you want to remove the selected projects?',
      disabled: (items: AnnotatorProject[]) =>
        items.some((item) => !item.owners.includes(user.id)),
    },
  ];

  return (
    <DefaultGrid
      loading={loading}
      columns={columns}
      rows={projects}
      defaultHeaderActions={defaultHeaderActions}
      defaultItemActions={defaultItemActions}
    />
  );
};

export default ResumeAnnotatorPojects;
