import { FileValidated } from '@dropzone-ui/react';
import { DeleteRounded } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useAnnotatorProjectData,
  useRemoveData,
  useUploadData,
} from '../../../api/annotator/data.api';
import { FileDrop } from '../../../components/file/file.drop.component';
import DefaultGrid, {
  DefaultHeaderActionProps,
  DefaultItemActionProps,
} from '../../../components/grid/default-grid.component';
import { TabCollection } from '../../../components/tab/tab.collection.component';
import { useUserProjectData } from '../../../context/user-project-data.context';
import usePermissions, {
  AnnotatorProjectPermission,
} from '../../../hooks/annotator/usePermissions';
import usePagination from '../../../hooks/common/usePagination';
import { AnnotatorData } from '../../../models/annotator/annotator-data.model';
import { AnnotatorProject } from '../../../models/annotator/annotator-project.model';

interface ImportDataProps {
  project: AnnotatorProject;
  onDataImported?: () => void;
}

const ImportDataComponent = ({ project, onDataImported }: ImportDataProps) => {
  const maxAllowedFiles = 100;

  const [inputFiles, setInputFiles] = useState<FileValidated[]>([]);
  const uploadDataMutation = useUploadData();

  const updateFiles = (incommingFiles: FileValidated[]) => {
    setInputFiles(incommingFiles);
  };

  const removeFile = (id: number | string | undefined) => {
    setInputFiles(inputFiles.filter((x: FileValidated) => x.id !== id));
  };

  const onDocumentsImported = () => {
    setInputFiles([]);
    toast.success('Documents imported successfully');
    onDataImported?.();
  };

  const onSubmitFiles = (files: File[]) => {
    if (files.length < 1 || !project) return;

    uploadDataMutation.mutate(
      files.map((file) => ({ project: project.id, data: { file } })),
      { onSuccess: onDocumentsImported }
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '50%',
        minWidth: '300px',
        margin: 'auto',
      }}
    >
      <FileDrop
        maxFiles={maxAllowedFiles}
        submitButtonText={'Import'}
        onSubmitFiles={onSubmitFiles}
        isLoading={uploadDataMutation.isLoading}
        onRemoveFile={removeFile}
        onUpdateFiles={updateFiles}
        files={inputFiles}
        loadingIndicator={
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              size={45}
              color="inherit"
              variant="indeterminate"
            />
          </Box>
        }
      />
    </Box>
  );
};

export interface AnnotatorDataGridProps {
  project: AnnotatorProject;
  onImportDataClicked?: () => void;
}

const AnnotatorDataGrid = ({
  project,
  onImportDataClicked,
}: AnnotatorDataGridProps) => {
  const { page, pageSize, setPage, setPageSize } = usePagination(0, 10);
  const { data, isLoading } = useAnnotatorProjectData(
    project.id,
    page,
    pageSize
  );
  const removeAnnotatorDataMutation = useRemoveData();

  const navigate = useNavigate();

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      hide: false,
      editable: false,
      flex: 1,
    },
    {
      field: 'dataType',
      headerName: 'Type',
      hide: false,
      editable: false,
      flex: 1,
    },
    {
      field: 'uploadedAt',
      headerName: 'Uploaded At',
      hide: false,
      editable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        new Date(params.value).toLocaleString(),
    },
  ];

  const { hasPermission } = usePermissions();

  const onRemoveAnnotatorData = (items: AnnotatorData[]) =>
    items.forEach((item) =>
      removeAnnotatorDataMutation.mutate(
        { id: item.id, project: item.project },
        { onError: () => toast('Unable to remove annotator data.') }
      )
    );

  const defaultHeaderActions: DefaultHeaderActionProps[] = [
    {
      text: 'Import',
      onClick: onImportDataClicked,
      hidden: !hasPermission(AnnotatorProjectPermission.AddData),
    },
    {
      text: 'Delete',
      disableOnNoSelection: true,
      color: 'error',
      confirmationMessage: 'Are you sure you want to delete the selected data?',
      onClick: onRemoveAnnotatorData,
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveData),
    },
  ];

  const defaultItemActions: DefaultItemActionProps[] = [
    {
      text: 'Annotate',
      onClick: (item: AnnotatorData) =>
        navigate(`/annotator/project/${item.project}/data/${item.id}`),
    },
    {
      icon: <DeleteRounded />,
      onClick: (item: AnnotatorData) => onRemoveAnnotatorData([item]),
      confirmationMessage: 'Are you sure you want to delete this data?',
      hidden: !hasPermission(AnnotatorProjectPermission.RemoveData),
    },
  ];

  return (
    <DefaultGrid
      defaultHeaderActions={defaultHeaderActions}
      defaultItemActions={defaultItemActions}
      rows={data?.data}
      loading={isLoading}
      columns={columnsDef}
      page={page}
      pageSize={pageSize}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      rowCount={data?.total ?? 0}
      paginationMode="server"
    />
  );
};

const AnnotatorDataPage = () => {
  const { userProjectData } = useUserProjectData();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { hasPermission } = usePermissions();

  return (
    <TabCollection
      selectionIndex={selectedTab}
      onSelectionChanged={(idx) => setSelectedTab(idx)}
      items={[
        {
          label: 'Data',
          content: (
            <AnnotatorDataGrid
              project={userProjectData.project}
              onImportDataClicked={() => setSelectedTab(1)}
            />
          ),
        },
        {
          label: 'Import Data',
          visible: hasPermission(AnnotatorProjectPermission.AddData),
          content: userProjectData?.project && (
            <ImportDataComponent
              project={userProjectData.project}
              onDataImported={() => setSelectedTab(0)}
            />
          ),
        },
      ]}
    />
  );
};

export default AnnotatorDataPage;
