import { Button, Stack, TextField, Typography } from '@mui/material';
import { ModalElement } from '../../modal/modal.element.component';
import { SliderPicker } from 'react-color';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateLabel } from '../../../api/annotator/labels.api';
import { AnnotatorProject } from '../../../models/annotator/annotator-project.model';
import { toast } from 'react-toastify';
import { SequenceAnnotatorLabel } from '../../../models/annotator/annotator-project-label';

export interface EditLabelComponentProps {
  open: boolean;
  onClose: () => void;
  project: AnnotatorProject;
  label: SequenceAnnotatorLabel;
}

const schema = yup
  .object({
    labelName: yup.string().required('The label display name is required'),
    labelKey: yup.string().required('The label key is required'),
    color: yup.string().required('The label color is required.'),
  })
  .required();

const EditLabelComponent = ({
  open,
  onClose,
  project,
  label,
}: EditLabelComponentProps) => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      labelName: label.name,
      labelKey: label.key,
      color: label.color,
    },
  });

  const updateLabelMutation = useUpdateLabel();
  const onSubmit = handleSubmit((data) =>
    updateLabelMutation.mutate(
      {
        project: project.id,
        key: label.key,
        data: {
          name: data.labelName,
          key: data.labelKey,
          color: data.color,
        },
      },
      {
        onSuccess: () => onClose?.(),
        onError: () => toast('Unable to update project label.'),
      }
    )
  );

  const content = (
    <Stack direction="column" component="form" spacing={2} onSubmit={onSubmit}>
      <Controller
        control={control}
        name="labelName"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            placeholder="The label display name"
            label="Display Name"
          />
        )}
      />
      <Controller
        control={control}
        name="labelKey"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            placeholder="The label key"
            label="Label key"
          />
        )}
      />
      <Stack spacing={1}>
        <Typography color="text.secondary">Color</Typography>
        <Controller
          control={control}
          name="color"
          render={({ field }) => (
            <SliderPicker
              {...field}
              color={field.value}
              onChange={(color) => field.onChange(color.hex)}
            ></SliderPicker>
          )}
        ></Controller>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button type="submit" variant="contained">
          Update
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <ModalElement title="Edit label" open={open} onClose={onClose}>
      {content}
    </ModalElement>
  );
};

export default EditLabelComponent;
