import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';

export interface LabelData {
  id: string;
  value: string;
  color: string;
}

interface LabelsProps {
  labels: LabelData[];
  onClick: (label: LabelData) => void;
}

const Labels = (props: LabelsProps) => {
  const { labels } = props;

  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        userSelect: 'none',
        height: '100%',
      }}
    >
      <List
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          flex: '1 1 0',
          userSelect: 'none',
        }}
      >
        {labels.map((label) => (
          <ListItem key={label.value}>
            <ListItemButton
              onClick={() => props.onClick(label)}
              sx={{
                borderLeft: `4px solid ${label.color}`,
                userSelect: 'none',
              }}
            >
              <ListItemText
                primary={label.value.replace('_', ' ')}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                  variant: 'body2',
                  textTransform: 'capitalize',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default Labels;
