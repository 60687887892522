import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  AddUserToProjectDto,
  UpdateProjectUserDto,
} from '../../dtos/annotator.project.dto';
import { UserProjectData } from '../../models/annotator/user-project-data.model';
import annotatorService from '../../services/annotator/annotator.service';
import { ANNOTATOR_PROJECT_USERS_KEY } from '../common';
import { queryClient } from '../query-client';

export const useProjectUsers = (projectId: string | undefined) => {
  const {
    data: users,
    isLoading,
    isError,
    refetch,
  } = useQuery<UserProjectData[]>(
    [...ANNOTATOR_PROJECT_USERS_KEY, projectId],
    () => {
      if (!projectId) return [];
      return annotatorService.getUsers(projectId);
    },
    { enabled: !!projectId }
  );

  return { users: users ?? [], isLoading, isError, refetch };
};

export interface AddUserToProjectData {
  project: string;
  data: AddUserToProjectDto;
}

export const useAddUserToProject = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    UserProjectData,
    AxiosError,
    AddUserToProjectData
  >(
    ANNOTATOR_PROJECT_USERS_KEY,
    (data) => {
      return annotatorService.addUser(data.project, data.data);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_USERS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface UpdateProjectUserData {
  id: string;
  project: string;
  data: UpdateProjectUserDto;
}

export const useUpdateProjectUser = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    UserProjectData,
    AxiosError,
    UpdateProjectUserData
  >(
    ANNOTATOR_PROJECT_USERS_KEY,
    (data) => {
      return annotatorService.updateUser(data.project, data.id, data.data);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_USERS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export interface RemoveUserFromProjectData {
  project: string;
  id: string;
}

export const useRemoveUserFromProject = () => {
  const { mutate, isLoading, isError, error } = useMutation<
    UserProjectData,
    AxiosError,
    RemoveUserFromProjectData
  >(
    ANNOTATOR_PROJECT_USERS_KEY,
    (data) => {
      return annotatorService.removeUser(data.project, data.id);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(ANNOTATOR_PROJECT_USERS_KEY),
    }
  );

  return { mutate, isLoading, isError, error };
};

export const useUserProjectMutations = () => {
  return {
    addUserToProject: useAddUserToProject(),
    removeUserFromProject: useRemoveUserFromProject(),
    updateProjectUser: useUpdateProjectUser(),
  };
};
