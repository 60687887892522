import { Stack } from '@mui/material';
import { DocumentAnnotation } from '../../../models/annotator/annotation.model';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import ResumeAnnotator from '../../../components/annotator/resume-annotator.component';
import { useUserProjectData } from '../../../context/user-project-data.context';
import { useState } from 'react';
import {
  useAnnotation,
  useUpdateAnnotation,
} from '../../../api/annotator/annotations.api';
import { useProjectLabels } from '../../../api/annotator/labels.api';
import { toast } from 'react-toastify';

interface PageParams {
  annotationId: string;
}

const EditProjectAnnotationPage = () => {
  const { userProjectData } = useUserProjectData();
  const { labels, isLoading: labelsLoading } = useProjectLabels(
    userProjectData.project.id
  );

  const updateAnnotation = useUpdateAnnotation();
  const { annotationId } = useParams<keyof PageParams>() as PageParams;
  const [annotations, setAnnotations] = useState<DocumentAnnotation[]>([]);

  const { annotation, isLoading: isAnnotationLoading } = useAnnotation(
    userProjectData.project.id,
    annotationId,
    { onSuccess: (res) => setAnnotations(res?.annotations ?? []) }
  );
  const text = annotation?.text ?? '';

  console.log(annotations);

  const onSaveAnnotations = () =>
    annotation &&
    updateAnnotation.mutate(
      {
        id: annotation.id,
        project: userProjectData.project.id,
        annotation: {
          annotations: annotations,
        },
      },
      {
        onSuccess: () => toast.success('Annotation updated!'),
        onError: () => toast.error('Unable to save annotation.'),
      }
    );

  const onUpdateAnnotations = (updatedAnnotations: DocumentAnnotation[]) =>
    setAnnotations(updatedAnnotations);

  const onResetAnnotations = () => {
    setAnnotations([]);
  };

  const isLoading = isAnnotationLoading || labelsLoading;

  return (
    <Stack sx={{ height: '100%' }}>
      <ResumeAnnotator
        onUpdate={onUpdateAnnotations}
        onReset={onResetAnnotations}
        onSave={onSaveAnnotations}
        annotations={annotations}
        text={text}
        labels={labels}
      />
      <Loader loading={isLoading} />
    </Stack>
  );
};

export default EditProjectAnnotationPage;
