import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useCreateProject } from '../../../api/annotator/projects.api';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../../../navigation/app-routes';
import { toast } from 'react-toastify';
import {
  ProjectType,
  ProjectTypes,
} from '../../../models/annotator/annotator-project.model';

const schema = yup
  .object({
    name: yup.string().required('The project name is required.'),
    description: yup.string().required('The project description is required.'),
    type: yup.string().required('The project type is required.'),
  })
  .required();

const CreateAnnotatorProjectPage = () => {
  const mutation = useCreateProject();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      type: ProjectType.Sequence,
    },
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) =>
    mutation.mutate(data, {
      onSuccess: () => navigate(AppRoutes.Annotator),
      onError: () => toast('Unable to create annotator project.'),
    })
  );

  return (
    <Box
      noValidate
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      onSubmit={onSubmit}
    >
      <Typography variant="h4">Create a Project</Typography>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={mutation.isLoading}
            required
            autoComplete="projectName"
            helperText={fieldState.error?.message}
            error={!!fieldState.error}
            label="Project Name"
            type="text"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            disabled={mutation.isLoading}
            autoComplete="projectDescription"
            helperText={fieldState.error?.message}
            error={!!fieldState.error}
            label="Project Description"
            type="text"
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Autocomplete
              disabled
              options={ProjectTypes}
              onChange={(e, v) => field.onChange(v)}
              value={field.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  placeholder="Enter the type of the project"
                  label="Type"
                />
              )}
            />
          );
        }}
      />
      <Button
        disabled={mutation.isLoading}
        sx={{ alignSelf: 'flex-start' }}
        variant="contained"
        type="submit"
      >
        Create
      </Button>
    </Box>
  );
};

export default CreateAnnotatorProjectPage;
