import { User } from '../auth/user.model';
import { AnnotatorProject } from './annotator-project.model';

export enum ProjectRole {
  Owner = 'Owner',
  Maintainer = 'Maintainer',
  Annotator = 'Annotator',
}

export const ProjectRoles = Object.keys(ProjectRole);

export interface UserProjectData {
  user: User;
  project: AnnotatorProject;
  role: ProjectRole;
  id: string;
}
